import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page/default/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Paper = makeShortcode("Paper");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from the `}<Link to="/" mdxType="Link">{`Remote Creative Work`}</Link>{` website, which will now be referred to as the `}<strong parentName="p">{`“Site”`}</strong>{`.`}</p>
    <Paper className="clear-top clear-bottom" mdxType="Paper">
      <Text className="compact-top heading" variant="h3" mdxType="Text">
  Personal Information collection
      </Text>
      <p>{`When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”`}</p>
      <p><strong parentName="p">{`We collect Device Information using the following technologies:`}</strong></p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`“Cookies”`}</strong>{` are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit `}<Link href="http://www.allaboutcookies.org" target="_blank" mdxType="Link">{`allaboutcookies.org`}</Link>{`.`}</li>
        <li parentName="ul"><strong parentName="li">{`“Log files”`}</strong>{` track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.`}</li>
        <li parentName="ul"><strong parentName="li">{`“Web beacons”`}</strong>{`, `}<strong parentName="li">{`“tags”`}</strong>{` and `}<strong parentName="li">{`“pixels”`}</strong>{` are electronic files used to record information about how you browse the Site.`}</li>
      </ul>
      <p>{`Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number. We refer to this information as “Order Information.”`}</p>
      <p>{`When we talk about `}<strong parentName="p">{`“Personal Information”`}</strong>{` in this Privacy Policy, we are talking both about Device Information and Order Information.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  How do we use your personal information?
      </Text>
      <p>{`We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:`}</p>
      <ul>
        <li parentName="ul">{`Communicate with you;`}</li>
        <li parentName="ul">{`Screen our orders for potential risk or fraud; and`}</li>
        <li parentName="ul">{`When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.`}</li>
      </ul>
      <p>{`We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Sharing your personal information
      </Text>
      <p>{`We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Stripe to power our online store -- you can read more about `}<Link href="https://stripe.com/privacy" target="_blank" mdxType="Link">{`how Stripe uses your Personal Information here`}</Link>{`. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about `}<Link href="https://www.google.com/intl/en/policies/privacy/" target="_blank" mdxType="Link">{`how Google uses your Personal Information here`}</Link>{`. You can also `}<Link href="https://tools.google.com/dlpage/gaoptout" target="_blank" mdxType="Link">{`opt-out of Google Analytics here`}</Link>{`.`}</p>
      <p>{`Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.`}</p>
      <p>{`As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the `}<Link href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work" target="_blank" mdxType="Link">{`Network Advertising Initiative’s (“NAI”) educational page here`}</Link>{`.`}</p>
      <p><strong parentName="p">{`You can opt out of targeted advertising by:`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://remotecreativework.us10.list-manage.com/unsubscribe?u=17a2551c515d1460d88320aca&id=574e335d76&e=&c=2e26a6e6b4"
          }}>{`MailChimp`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.google.com/settings/ads/anonymous"
          }}>{`Google`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
          }}>{`Bing`}</a></li>
      </ul>
      <p>{`Additionally, you can opt out of some of these services by visiting the `}<Link href="http://optout.aboutads.info/" target="_blank" mdxType="Link">{`Digital Advertising Alliance’s opt-out portal`}</Link>{`.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Do not track
      </Text>
      <p>{`Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Your rights
      </Text>
      <p>{`You have the right to know about the personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.`}</p>
      <p>{`Additionally, we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of your country of residence.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Data retention
      </Text>
      <p>{`When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Children’s privacy
      </Text>
      <p>{`Our services are not intended for use by children under the age of 13.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Changes
      </Text>
      <p>{`We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.`}</p>
      <Text className="heading" variant="h3" mdxType="Text">
  Contact us
      </Text>
      <Text mdxType="Text">
  If you have questions about this Privacy Policy, please contact us by email at{" "}
  <Link href="mailto:support@remotecreativework.com" mdxType="Link">
    support@remotecreativework.com
  </Link>
  .
      </Text>
    </Paper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      